import React from 'react';
import {ReactComponent as Magic} from "../resources/magic.svg";
import {ReactComponent as Close} from "../resources/close.svg";
import {ReactComponent as Add} from "../resources/add.svg";
import {ReactComponent as Fire} from "../resources/fire.svg";
import {ReactComponent as Healing} from "../resources/give_health.svg";
import {ReactComponent as Star} from "../resources/star.svg";
import {ReactComponent as SpellSearch} from "../resources/spellSearch.svg";
import {ReactComponent as Check} from "../resources/check.svg";



export const Icon = ({type}) =>{
    switch (type.toLowerCase()) {
        case 'magic':
            return <Magic/>
        case 'close':
            return <Close/>
        case 'add':
            return <Add/>
        case 'fire':
            return <Fire/>
        case 'healing':
            return <Healing/>
        case 'spellsearch':
            return <SpellSearch/>
        case 'check':
            return <Check/>
        default:
            return <Star/>
    }
}
