import React from 'react';
import {ResetButton} from "grevlingui";
import './Plan.css';
import {Settings} from "../Settings/Settings";
import {Spell} from "../Spell/Spell";
import {isDomainSpell} from "../../utils";
import {ChannelEnergy} from "./ChannelEnergy/ChannelEnergy";

export const Plan = ({
                         plan,
                         setPlan,
                         slots,
                         setSlots,
                         setSpellLevel,
                         setFilters,
                         channels,
                         setChannels
}) => {
    const reset = () => {
        setChannels(c => ({
            ...c,
            spent: []
        }))
        setPlan(
            plan
                // .filter(o => Array.isArray(o))
                .map(l => l.map(s => ({...s, spent: false}))))
    }

    const setDomainFilters = (domainFilters) =>
        setFilters(f => ({
            ...f,
            healing: domainFilters,
            fire: domainFilters
        }))

    const renderSpellSlot = (spellLevel, slot) => {
        const domainSlot = spellLevel > 0 && slot === 0;
        const spell = plan[spellLevel][slot];
        if (!spell)
            return (
                <div
                    key={`spellSlot-${slot}`}
                    className={`spellSlot ${domainSlot ? 'highlight' : ''}`}
                    onClick={() => {
                        setSpellLevel(spellLevel);
                        setDomainFilters(domainSlot);
                    }}
                >
                    Empty slot {slot + 1} {domainSlot ? '(domain)' : ''}
                </div>
            );


        const removeSpell = () => {
            let newPln = [...plan];
            let newPlnLevel = newPln[spellLevel] = [...newPln[spellLevel]]
            if (spellLevel !== 0 && slot === 0 && !!newPlnLevel[slot + 1] && !isDomainSpell(newPlnLevel[slot + 1])) {
                newPlnLevel[0] = undefined;
                const nextDomainSpellIndex =
                    newPlnLevel.findIndex(s => s && isDomainSpell(s));
                if (nextDomainSpellIndex > -1) {
                    newPlnLevel[0] = newPlnLevel[nextDomainSpellIndex];
                    newPlnLevel.splice(nextDomainSpellIndex, 1);
                } else
                    newPlnLevel[0] = undefined;

            } else {
                newPlnLevel.splice(slot, 1);
            }
            setPlan(newPln);
        }


        return (
            <Spell
                key={`spellSlot-${slot}`}
                highlight={spellLevel > 0 && slot === 0}
                spell={spell}
                invalid={slot >= slots[spellLevel]}
                spent={spell.spent}
                spend={() => {
                    let newPln = [...plan];
                    let newPlnlevel = newPln[spellLevel] = [...newPln[spellLevel]]
                    newPlnlevel[slot] = {
                        ...newPlnlevel[slot],
                        spent: !newPlnlevel[slot].spent
                    };
                    setPlan(newPln);
                }}
                remove={removeSpell}
            />
        );

    }

    const renderPlanLevel = (l) => {
        const levelSlots = [];
        for (let slot = 0; slot < Math.max(slots[l], plan[l].length); slot++)
            levelSlots.push(renderSpellSlot(l, slot))
        return (
            <div
                className="planLevel"
                key={`planLevel-${l}`}
            >
                <div className="fadeHeader planLevelHeader">
                    <h3>Level {l}</h3>
                </div>
                {levelSlots}
            </div>
        )
    }

    const renderPlan = () => {
        if (!slots)
            return;
        const result = []

        if (channels && channels.channels > 0) {
            result.push(<ChannelEnergy
                key={'channel_energy'}
                channels={channels}
                setChannels={setChannels}
            />)
        }

        for (let spellLevel = 0; spellLevel < slots.length; spellLevel++) {
            result.push(renderPlanLevel(spellLevel));
        }
        return result;
    }

    return (
        <div className="plan">
            <ResetButton
                color="black"
                backgroundColor="dodgerblue"
                onClick={reset}
            />
            <div className="planContent">
                <Settings
                    setSlots={setSlots}
                    channels={channels}
                    setChannels={setChannels}
                />
                <h1 className="planHeader">PLN</h1>
                <div className="planScroller scroller">
                    {renderPlan()}
                    <div className="spacer"/>
                </div>
            </div>
        </div>
    );
}