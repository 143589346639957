import React, {useState, useEffect} from 'react';
import './App.css';
import {Plan} from "./components/Plan/Plan";
import {SpellList} from "./components/SpellList/SpellList";
import {getSpellLevel, isFireSpell, isHealingSpell} from "./utils";
import {getSpells} from "./firebase/storage";


const getPlan = () => {
    const storedPlan = localStorage.getItem('klerik-plan');
    if (storedPlan) return JSON.parse(storedPlan);
    return [[], [], [], [], [], [], [], [], [], []]
}

const getFavs = () => {
    const stored = localStorage.getItem('klerik-favs');
    if (stored) return JSON.parse(stored);
    return [];
}

const getFilters = () => {
    const stored = localStorage.getItem('klerik-filters');
    if (stored) return JSON.parse(stored);
    return {search: ''};
}

const getChannels = () => {
    const stored = localStorage.getItem('klerik-channels');
    if (stored) return JSON.parse(stored);
    return {channels: 0, spent: []};
}

const matches = (search, spell) => {
    if (!search) return true;
    return (spell.name.toLowerCase().includes(search.toLowerCase()));
}

const listRef = React.createRef();
const plnRef = React.createRef();

function App() {
    const [plan, setPlan] = useState(getPlan())
    const [slots, setSlots] = useState([])
    const [spellLevel, setSpellLevel] = useState(0);
    const [favs, setFavs] = useState(getFavs());
    const [filters, setFilters] = useState(getFilters());
    const [spellMap, setSpellMap] = useState()
    const [spells, setSpells] = useState();
    const [channels, setChannels] = useState(getChannels());

    useEffect(() => {
        localStorage.setItem('klerik-plan', JSON.stringify(plan));
    }, [plan])
    useEffect(() => {
        localStorage.setItem('klerik-filters', JSON.stringify(filters))
    }, [filters])
    useEffect(() => {
        localStorage.setItem('klerik-favs', JSON.stringify(favs))
    }, [favs])
    useEffect(() => {
        localStorage.setItem('klerik-channels', JSON.stringify(channels))
    }, [channels])

    useEffect(() => {
        getSpells().then(setSpells)
    }, [])

    useEffect(() => {
        if (!spells) return;
        let filtered = [...spells];
        if (filters.fire || filters.healing)
            filtered = filtered.filter(s => {
                if (filters.fire && filters.healing)
                    return isHealingSpell(s) || isFireSpell(s);
                if (filters.fire) {
                    return isFireSpell(s);
                }
                if (filters.healing) {
                    return isHealingSpell(s);
                }
                return true;
            })

        if (filters.favs)
            filtered = filtered.filter(s => favs.includes(s.id))

        if (filters.search)
            filtered = filtered.filter(s => matches(filters.search, s));

        if (filtered.length > 200)
            filtered = filtered.filter((s) => getSpellLevel(s) === spellLevel)

        if (filtered.length === 0)
            setSpellMap({empty: true})
        else
            setSpellMap(filtered.reduce((acc, s) => {
                const lvl = getSpellLevel(s);
                let spellList = acc[lvl];
                if (!spellList) acc[lvl] = spellList = [];
                spellList.push(s);
                return acc;
            }, {}));

    }, [filters, favs, spellLevel, spells])

    useEffect(() => {
        setTimeout(() =>
                plnRef.current &&
            plnRef.current.scrollIntoView({behavior: "smooth"})
        , 10)

    }, [])

    return (
        <div className="App">
            <div className="appContent">
                <div className="scrollyContent scroller">
                    <div className="page" ref={listRef}>
                        <SpellList
                            spellLevel={spellLevel}
                            setSpellLevel={setSpellLevel}
                            plan={plan}
                            setPlan={setPlan}
                            favs={favs}
                            setFavs={setFavs}
                            filters={filters}
                            setFilters={setFilters}
                            spellMap={spellMap}
                        />
                    </div>
                    <div className="page" ref={plnRef}>
                        <Plan
                            channels={channels}
                            setChannels={setChannels}
                            plan={plan}
                            setPlan={setPlan}
                            slots={slots}
                            setSlots={setSlots}
                            setFilters={setFilters}
                            setSpellLevel={l => {
                                setSpellLevel(l);
                                listRef.current.scrollIntoView({behavior: "smooth",});
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}


export default App;
