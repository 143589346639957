import React, {useEffect, useState} from 'react';
import './Spell.css';
import {getSpellColor, getSpellLevel} from "../../utils";
import {Icon} from "../Icon";

const attributesToShowOver = [
    'casting_time',
    'duration',
    'range',
    // 'cleric',
];

const attributesToShowUnder = [
    // 'cleric',
    // 'school',
    'alignment',
    'targets',
    'components',
    'effect',
    // 'saving_throw',
    // 'spell_resistance',
    // 'mythic_text',
];

export const Spell = ({spell, add, added, remove, invalid, highlight, spend, spent, fav, toggleFav}) => {
    const [show, setShow] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [adding, setAdding] = useState(false);

    useEffect(() => {
        if (adding)
            setTimeout(() =>
                    setAdding(false)
                , 50)
    }, [adding])

    const renderSavingThrow = (spell) => {
        if (!spell.saving_throw || spell.saving_throw.includes('harmless')) return <></>;
        return renderAttribute('saving_throw');
    }

    const renderAttribute = (key) => spell[key] ? renderPair(key.replace('_', ' '), spell[key]) : <></>;

    const renderPair = (key, value) => (
        <p key={key}><b>{key}:</b> {value}</p>
    )

    const [color1, color2] = getSpellColor(spell, invalid);

    const level = getSpellLevel(spell);

    const addedIndicator = () => {
        if (!level || !add || !added) return <></>;
        return <div className="addedCount">
            {
                [...Array(Math.max(added)).keys()].map(n => (
                    <div
                        key={`addedDot-${n}`}
                        className="addedDot"
                    />
                ))
            }
        </div>
    }

    const addButton = () => {
        if (!add || (!level && added)) return <></>;
        return (
            <button
                className="spellActionButton addSpellButton"
                onClick={() => {
                    setAdding(true);
                    add(spell);
                }}
            >
                <Icon type="add"/>
            </button>
        );
    }

    return (
        <div
            className={`spell ${invalid ? 'invalid' : ''} ${spent ? 'spent' : ''} ${highlight ? 'highlight' : ''}`}
            style={{backgroundColor: color1}}
        >
            <div className={`addBlinker ${adding ? 'show' : ''}`}/>
            <div className="spellHeader" style={{backgroundColor: color2}}>
                <h3
                    onClick={(e) => {
                        if (!invalid &&
                            !['removeSpellButton', 'addSpellButton', 'spendSpellButton']
                                .includes(e.target.classList[0])
                        ) setShow(s => !s)
                    }}
                >
                    {spell.name}
                    {addedIndicator()}
                </h3>
                {addButton()}
                {remove && <button
                    className="spellActionButton removeSpellButton"
                    onClick={remove}
                >
                    <Icon type="close"/>
                </button>}
                {spend && level > 0 && !invalid && <button
                    className="spellActionButton spendSpellButton"
                    onClick={spend}
                >
                    <Icon type="magic"/>
                </button>}
                {toggleFav && <button
                    className={`spellActionButton favSpellButton ${fav ? 'active' : ''}`}
                    onClick={toggleFav}
                >
                    <Icon type="star"/>
                </button>}
            </div>

            {show && <div className="info">
                {spell.short_description && (
                    <>

                        <p
                            className="short_description"
                            style={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                            }}
                        >
                            {spell.short_description}
                        </p>
                    </>
                )}
                <div className="attributes">
                    {attributesToShowOver.filter(a => !!spell[a]).map(renderAttribute)}
                    {renderPair('domains', spell.domains.length > 0 ? spell.domains.join(', ') : 'none')}
                    {attributesToShowUnder.filter(a => !!spell[a]).map(renderAttribute)}
                    {/*{renderAttribute(spell.short_description ? 'short_description' : 'description')}*/}
                    {renderSavingThrow(spell)}
                </div>
                {spell.description && (
                    <div
                        className="details"
                        style={{backgroundColor: color2}}
                        onClick={() => setShowDetails(sd => !sd)}
                    >
                        {showDetails ?
                            spell.description_formatted ?
                                (<div dangerouslySetInnerHTML={{__html: spell.description_formatted}}/>) :
                                (<p>spell.description</p>)
                            : (<b>
                            <span
                                style={{
                                    display: 'block',
                                    textAlign: 'center',
                                    padding: '10px 0'
                                }}
                            >
                                Details
                            </span>
                            </b>)}
                    </div>
                )}
            </div>}
        </div>)
};