import React, { Fragment } from 'react';
import {NumberSelect} from 'grevlingui';
import {Spell} from "../Spell/Spell";
import './SpellList.css'
import {Icon} from "../Icon";
import {Loader} from "../Loader/Loader";
import {isDomainSpell} from "../../utils";

const spellLevelRefs = [];

export const SpellList = ({
                              spellLevel,
                              setSpellLevel,
                              plan,
                              setPlan,
                              favs,
                              setFavs,
                              filters,
                              setFilters,
                              spellMap
}) => {
    const setFilter = (key, value) => setFilters(f => ({...f, [key]: value}))

    const add = (l, s) => {
        const pln = [...plan];
        pln[l] = [...pln[l]]
        if (l === '0') {
            pln[0].push(s);
            setPlan(pln);
            return;
        }

        if (isDomainSpell(s)) {
            if (!pln[l][0])
                pln[l][0] = s;
            else
                pln[l].push(s);
        } else {
            if (pln[l].length === 0)
                pln[l][1] = s;
            else
                pln[l].push(s);
        }
        setPlan(pln);
    }

    const renderSpellGroup = (level) => (
        <Fragment key={`spells-of-level-${level}`}>
            <div className="fadeHeader spellLevelHeader">
                <h3 ref={node => spellLevelRefs[level] = node}>
                    Level {level}
                </h3>
            </div>
            {spellMap[level].map((spell) => {
                const added = plan[level].filter(s => s && spell && s.id === spell.id).length

                return (
                    <Spell
                        key={spell.name}
                        spell={spell}
                        add={(s) => add(level, s)}
                        added={added}
                        toggleFav={() => {
                            const newFavs = [...favs];
                            const i = favs.findIndex(id => id === spell.id);
                            if (i === -1)
                                newFavs.push(spell.id)
                            else
                                newFavs.splice(i, 1);
                            setFavs(newFavs);
                        }}
                        fav={favs.includes(spell.id)}
                    />
                )
            })}
        </Fragment>
    )

    const content = spellMap ? (
        <div className="spellListContent">
            <div className="filters">
                <div className="filterButtons">
                    <button
                        className={`filterButton favs ${filters.favs ? 'active' : ''}`}
                        onClick={() => setFilter('favs', !filters.favs)}
                    >
                        <Icon type="star"/>
                    </button>
                    <button
                        className={`filterButton healing ${filters.healing ? 'active' : ''}`}
                        onClick={() => setFilter('healing', !filters.healing)}
                    >
                        <Icon type="healing"/>
                    </button>
                    <button
                        className={`filterButton fire ${filters.fire ? 'active' : ''}`}
                        onClick={() => setFilter('fire', !filters.fire)}
                    >
                        <Icon type="fire"/>
                    </button>
                    <NumberSelect
                        value={spellLevel}
                        setValue={setSpellLevel}
                        size={50}
                        flip
                        thin
                        shadow
                        numberBgColor="#333"
                        selectorColor="#666"
                        pointerColor="#888"
                        min={0}
                        max={9}
                    />
                </div>
                <div className="searchInput">
                    <input
                        type="text"
                        onChange={e => setFilter('search', e.target.value)}
                        value={filters.search ?? ''}
                    />
                    <button
                        className={`resetSearchButton ${filters.search.length > 0 ? 'active' : ''}`}
                        onClick={() => setFilter('search', '')}
                    >
                        <Icon type="close"/>
                    </button>
                </div>
            </div>
            <div className="spellsWrapper scroller">
                {
                    spellMap.empty ? (
                        <Loader/>
                    ) : (
                        <div className="spells">
                            {Object.keys(spellMap).map(renderSpellGroup)}
                        </div>
                    )
                }
                <div className="spacer"/>
            </div>
        </div>
    ) : (
        <Loader move/>
    )

    return (<div className="spellList">{content}</div>);
}