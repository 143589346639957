
export const isFireSpell = (s) =>
    s.domains.fire !== undefined

export const isHealingSpell = (s) =>
    s.domains.healing !== undefined

export const isDomainSpell = (s) => isFireSpell(s) || isHealingSpell(s);



export const getSpellLevel = (s) => {
    if (s.cleric) return parseInt(s.cleric);
    let domainLevel = s.domains.fire;
    if (domainLevel !== undefined) return domainLevel;
    domainLevel = s.domains.healing;
    if (domainLevel !== undefined) return domainLevel;
    return s.SLA_Level;
}

export const getSpellColor = (spell, invalid) => {
    if (invalid) return ['#757575', '#989898']
    if (isHealingSpell(spell))
        return ['#e34d98', 'hotpink']
        // return ['#009324', '#0fc23b']
    if (isFireSpell(spell))
        return ['#de6f00', '#ff9100']
    // return ['#1f72c4', '#1E90FFFF']
    return ['#663399', '#894ace']
}

// const f = (
//     {
//         pink: "#E34D98",
//         fire: "#ff9100",
//         purple: "#663399",
//         whitish: "#D9D9D9",
//
//
//     }
// )