import {initializeApp} from "firebase/app";
import {getStorage, ref, getBlob} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyB2v7CXHWYJl1iQFd3vvR53I16mtQOC-k8",
    authDomain: "dubbyadubbyadubbya.firebaseapp.com",
    databaseURL: "https://dubbyadubbyadubbya.firebaseio.com",
    projectId: "dubbyadubbyadubbya",
    storageBucket: "dubbyadubbyadubbya.appspot.com",
    messagingSenderId: "450239710849",
    appId: "1:450239710849:web:de98a1810b513c57b26f80"
};

initializeApp(firebaseConfig);

const storage = getStorage();
const pathReference = ref(storage, '/spells.json');

export const getSpells = () =>
    new Promise(resolve => {
    getBlob(pathReference)
        .then(blob => {
            const fr = new FileReader();
            fr.onload = () => resolve(JSON.parse(fr.result));
            fr.readAsText(blob);
        })
})
