import React, {useEffect, useState} from 'react';
import {Menu, NumberSelect, UseEffectPartialDeps} from "grevlingui";
import './Settings.css'

const levelSpellSlots = [
    [],
    [3, 2],
    [4, 3],
    [4, 3, 2],
    [4, 4, 3],
    [4, 4, 3, 2],
    [4, 4, 4, 3],
    [4, 5, 4, 3, 2],
    [4, 5, 4, 3, 2],
    [4, 5, 5, 4, 3, 2],
    [4, 5, 5, 4, 4, 3],
    [4, 5, 5, 5, 4, 3, 2],
    [4, 5, 5, 5, 4, 4, 3],
    [4, 5, 5, 5, 5, 4, 3, 2],
    [4, 5, 5, 5, 5, 4, 4, 3],
    [4, 5, 5, 5, 5, 5, 4, 3, 2],
    [4, 5, 5, 5, 5, 5, 4, 4, 3],
    [4, 5, 5, 5, 5, 5, 5, 4, 3, 2],
    [4, 5, 5, 5, 5, 5, 5, 4, 4, 3],
    [4, 5, 5, 5, 5, 5, 5, 5, 4, 4],
    [4, 5, 5, 5, 5, 5, 5, 5, 5, 5]
]

const getWisModifiers = (wis) => wis < 10 ? null :
    [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 1, 1, 0, 0, 0, 0, 0, 0, 0],
        [0, 1, 1, 1, 0, 0, 0, 0, 0, 0],
        [0, 1, 1, 1, 1, 0, 0, 0, 0, 0],
        [0, 2, 1, 1, 1, 1, 0, 0, 0, 0],
        [0, 2, 2, 1, 1, 1, 1, 0, 0, 0],
        [0, 2, 2, 2, 1, 1, 1, 1, 0, 0],
        [0, 2, 2, 2, 2, 1, 1, 1, 1, 0],
        [0, 3, 2, 2, 2, 2, 1, 1, 1, 1],
        [0, 3, 3, 2, 2, 2, 2, 1, 1, 1],
        [0, 3, 3, 3, 2, 2, 2, 2, 1, 1],
        [0, 3, 3, 3, 3, 2, 2, 2, 2, 1],
        [0, 4, 3, 3, 3, 3, 2, 2, 2, 2],
        [0, 4, 4, 3, 3, 3, 3, 2, 2, 2],
        [0, 4, 4, 4, 3, 3, 3, 3, 2, 2],
        [0, 4, 4, 4, 4, 3, 3, 3, 3, 2],
        [0, 5, 4, 4, 4, 4, 3, 3, 3, 3]
    ][Math.floor((wis - 10) / 2)];

const getKlerikLevel = () => {
    const stored = localStorage.getItem('klerik-level');
    if (stored) return parseInt(stored);
    return 1;
}

const getKlerikWisdom = () => {
    const stored = localStorage.getItem('klerik-wisdom');
    if (stored) return parseInt(stored);
    return 10;
}

export const Settings = ({
                             setSlots,
                             channels,
                             setChannels
                         }) => {
    const [level, setLevel] = useState(getKlerikLevel());
    const [wisdom, setWisdom] = useState(getKlerikWisdom());

    useEffect(() => {
        localStorage.setItem('klerik-level', JSON.stringify(level));
    }, [level])

    useEffect(() => {
        // console.log('setting wisdom:', wisdom);
        localStorage.setItem('klerik-wisdom', wisdom);
    }, [wisdom])

    UseEffectPartialDeps(() => {
        if (wisdom < 10) {
            setSlots([])
            return;
        }
        const slots = [...levelSpellSlots[level]];
        const modifiers = getWisModifiers(wisdom);
        for (let i = 0; i < slots.length; i++) {
            slots[i] = slots[i] + modifiers[i];
        }
        setSlots(slots)
    }, [level, wisdom])

    const renderInput = (value, setValue, max = 20, min = 1) => (
        <NumberSelect
            value={value}
            setValue={setValue}
            min={min}
            max={max}
            size={100}
            shadow
            numberBgColor="#333"
            selectorColor="#666"
            pointerColor="#888"
        />
    )

    return (
        <Menu
            settings fader left
            color="black"
            bgColor="dodgerblue"
        >
            <div className="settings">
                <div>
                    <h2>Level</h2>
                    {renderInput(level, setLevel)}
                </div>
                <div>
                    <h2>Wisdom</h2>
                    {renderInput(wisdom, setWisdom, 45)}
                </div>
                <div>
                    <h2>Channels</h2>
                    {renderInput(channels.channels, v => setChannels(ov => ({
                        ...ov,
                        channels: v
                    })), 10, 0)}
                </div>
                <div/>
            </div>
        </Menu>
    );
}