import React from 'react';
import classNames from 'classnames';
import './ChannelEnergy.css';

export const ChannelEnergy = ({channels, setChannels}) => {

    const spacerRatio = .2;
    const total = channels.channels;
    let row1, row2;
    if (total > 4) {
        row1 = Math.floor(total / 2);
        row2 = Math.ceil(total / 2);
    } else
        row1 = total;

    const widthUnits = row1 + ((row1 - 1) * spacerRatio);

    const totalVw = 60;
    const vwPerUnit = Math.min(totalVw / widthUnits, 15);

    const padding = total > 4 && total % 2 === 0 ? ((vwPerUnit) / 2) * (1 + (spacerRatio * 2)) : 0;

    const renderChannel = (rowIndex) => (i, offset) => {
        const cIndex = i + offset + rowIndex;
        const spent = channels.spent[cIndex];
        return (
            <div
                key={`c-${rowIndex}-${i}-${offset}`}
                className={classNames('channel', {spent})}
                onClick={() => {
                    setChannels(pv => {
                        const spent = [...pv.spent];
                        spent[cIndex] = !spent[cIndex];
                        return ({
                            ...pv,
                            spent
                        })
                    })
                }}
                style={{
                    width: `${vwPerUnit}vw`,
                    height: `${vwPerUnit}vw`,
                    margin: `0 ${vwPerUnit * spacerRatio}vw`
                }}
            >
                <div className="orb"/>
            </div>
        )
    }

    const renderChannels = (data, rowIndex) => data ? (
        <div
            className="channels"
            style={{
                marginTop: `${vwPerUnit * spacerRatio}vw`,
                paddingLeft: padding && rowIndex % 2 === 0 ? `${padding}vw` : 0,
                paddingRight: padding && rowIndex % 2 !== 0 ? `${padding}vw` : 0,
            }}
        >
            {[...Array(data).keys()].map(renderChannel(rowIndex))}
        </div>
    ) : <></>

    return (
        <div className="channelEnergy">
            <div className="fadeHeader channelHeader">
                <h3>Channel Energy</h3>
            </div>
            <div/>
            {renderChannels(row1, 0)}
            {renderChannels(row2, 1)}
            <div className="spacer"/>
        </div>
    );
}